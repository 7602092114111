import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import immigrationLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'
const aboutText =config.ImmigrationLaw;
// const aboutText = [
//     { text: '' },
//     { text: 'Planning to migrate or settle in Canada, one of the best countries in the world? Excellent decision! Canada offers immense opportunities, be it for newcomers from all over the globe or for temporary residents planning to settle here. Immigration Law covers anything and everything from Citizenship, Permanent residence, Spousal sponsorship, Study permit, Work permit, temporary residence, Refugee and Humanitarian applications and Judicial Review.' },
//     { text: 'The immigration laws & intake capacities in Canada are constantly updated by the Canadian Government. We, at Ardent Law Firm, specialize in all types of immigration issues and focus on turning the dreams of aspiring immigrants into reality. Our dedicated team is well versed and up to date with the constantly changing immigration laws and requirements. ' },
//     { text: 'We, at Ardent Law Firm, will sit down with you to understand your exact immigration needs and will offer you the best in depth knowledge about all the routes available to you to immigrate and settle in Canada. The applications can be overwhelming; however, our team will assist you throughout the process all the way from filling out the forms, online profiles to ironing out any legal issues or complications.' },
//     { texth: 'We specialize in the following areas:' },
//     { textc: 'Temporary Residence including Visit Visa, Work Permits, Study Permits.' },
//     { textc: 'Permanent Residence including Express Entry, PNP’s' },
//     { textc: 'Sponsorship Applications including Spousal sponsorship, Parental sponsorship, Child sponsorship' },
//     { textc: 'Investors category and Self-employed category' },
//     { textc: 'Refugee Applications' },
//     { textc: 'Judicial Review and Reconsideration' },
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
   
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Immigration Law'}
]

const ImmigrationLaw = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="IMMIGRATION"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="IMMIGRATION"
                images={immigrationLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default ImmigrationLaw