import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/FamilyLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'
 
import './style.scss'
import config from '../../../config'


const aboutText =config.BusinessLaw;
// const aboutText = [
//     { text: '' },
//     { text: 'Businesses need a certified & highly efficient Business Lawyer Ontario to manage their Business operations efficiently. Whether it\'s an addition or removal of a critical stakeholder, buy or sale of any property or Business, acquisition or financing, no Business can operate smoothly without a team of professional Business lawyers in Canada. ' },
//     { text: 'So if you are a corporate or a rapidly growing business, it\'s better to have a team of lawyers who can manage all your legal Business issues effectively. At Ardent Law firm, we offer tailored & fully customized legal services to Businesses of all sizes. ' },
//     { text: 'Our goal is always to avoid confrontations & mitigate the risk with dispute resolutions & mediation. In short, we will do everything, keeping your best interest in mind. ' },
//     { texth: 'What services do we offer?' },
//     { textc: 'Our comprehensive Corporate Lawyer services include everything that your Business will ever need. Here is the list of services that we offer.' },
//     { textc: '•	Shareholder and partnership agreements' },
//     { textc: '•	Employment agreements' },
//     { textc: '•	Incorporation (federal and provincial)' },
//     { textc: '•	Contract negotiation' },
//     { textc: '•	Buying and selling businesses' },
//     { textc: '•	Commercial agreements' },
//     { textc: '•	Supply and distribution agreements' },
//     { textc: '•	Mediation for Disputes (corporate, management, shareholder)' },
//     { textc: '•	Mergers and acquisitions' },
//     { textc: '•	Non-compete and Non-disclosure agreements' },
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
 
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Corporate/Business Law'}
]

const FamilyLaw = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Corporate/Business Law"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Corporate/Business Law"
                images={familyLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default FamilyLaw