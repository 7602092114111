import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import '../src/css/flaticon.css'
import '../src/css/odometer-theme-default.css'
import '../src/css/animate.css'
import App from '../src/containers/app';
import * as serviceWorker from './serviceWorker';

// import { Provider } from 'react-redux'
// import store from './store'
import './index.scss';

const isLocal = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
 
if  (!isLocal && window.protocol === 'http:') {
  window.location.replace(`https:${window.href.substring(window.protocol.length)}`);
}
else{
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
    <App />
    </React.StrictMode>
);
// const app = (
//     <Provider store={store}>
//         <App />
//     </Provider>
// )

// ReactDOM.createRoot(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
 }