import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/ImmigrationLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'
import config from '../../../config'
import './style.scss'
const aboutText =config.RealEstateLaw;
// const aboutText = [
//     { text: '' },
//     { text: 'Making a decision to buy any property is a big one, financially & emotionally. So you need support from a team of experienced & reliable Real Estate Law experts in Ontario. And we are here to provide you with that support. ' },
//     { text: 'At every stage of your Property buying or selling, we will ensure you are not stuck or face some legal issues. And in case anything goes wrong, we will also represent you in Real Estate Litigation to protect your rights. We are a Professional Real Estate Lawyer Ontario team specializing in Real Estate laws. Our team is well-equipped with the knowledge & resources needed to ensure a smooth buying of real estate. When it comes to the legality of all the paperwork, our team acts as your support line. We will perform an extensive analysis of your contract to ensure everything is in line.' },
//     { text: 'We will make sure you are getting what you expect & nothing less. Apart from this, we will also help you negotiate transaction terms on your behalf so you can sit back & relax. Our lawyers are well-versed in terms of laws & hence will ensure you are not misled in any form via your contract. We will do everything in our power to ensure that your rights are protected in the best possible way. ' },
//     { text: 'On Closing Day, we will assist you at every step of the final process. As this is the day when you will actually become your property owner, we will ensure all the paperwork is in line. Our team will make sure nothing goes wrong at the last moment & hence protect you from any fraud or legal issues. ' },
//     { text: 'We will also help you with making financial transactions & in creating Statements of Adjustments. We will ensure you are not charged with any unnecessary fees & are getting precisely what you were promised.' },
    
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
  
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]


const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Real Estate Law'}
]

const RealEstateLaw = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Real Estate Law"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Real Estate Law"
                images={familyLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default RealEstateLaw