import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
const services = [
  { icon: "fi flaticon-lawyer", subtitle: "Book Your", title: "Appointment",link:"../contact" },
//  { icon: "fi flaticon-scale", subtitle: "Student Vis", title: "Student Visa Menu",link:"ImmigrationLaw"  },
];
const Service = (props) => {
  return (
    <div className={props.className}>
      <div className="container">
        <div className="row jconten">
          {services.map((service, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              
              <div className="serviceItem">
                <div className="serviceIcon">
                  <i className={service.icon}></i>
                </div>
                <Link  to={service.link}> 
                <div className="serviceText">
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
                </Link>
                </div>
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
