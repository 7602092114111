import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'
import config from '../../../config'
import './style.scss'


const aboutText =config.About;
// const aboutText = [
//     { text: '' },
//     { text: 'Ardent Law firm resulted from an idea to establish a reliable, trustworthy & full-service Law Firm.' },
//     { text: 'At Ardent Law Firm, we have accumulated years of experience & a team of super-talented and specialized lawyers. We listen to our clients\' issues in detail & later offer our tailored solutions to help them out.' },
//     { text: 'We believe in listening & inquiring about every little detail that matters. So when you hire us, you can expect good attention to everything you have to say. On top of it, we will ask the pointed but vital questions for additional information The better you answer them, the better we will be able to offer the solution. Because, ultimately, it\'s your answers that will help us prepare a rock-solid case for you.' },
//     { texth: 'Consult now' },
//     { textc: 'At Ardent, we value our client\'s right to justice above all. And that\'s precisely what has helped us cement our position in the justice system over the years. Whether its Criminal Law, Real Estate Law, Family Law, Business Law or Immigration Law, we have a dedicated team of specialists to assist you.' },
//     { textc: 'In short, we are a full-service Law firm in Ontario that specializes in assisting clients from various sections of society. ' },
    
// ]

const services = [
    {
        icon: config.PageMenu.FamilyLaw.icon,
        title: config.PageMenu.FamilyLaw.title,
        content: config.PageMenu.FamilyLaw.content,
        link:config.PageMenu.FamilyLaw.link
    },
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About us'}
]

const AboutUs = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Full-service law firm in Ontario that you can rely on."
                images={about}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default AboutUs