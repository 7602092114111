import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/FamilyLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'
import config from '../../../config'

const aboutText =config.FamilyLaw;
// const aboutText = [
//     { text: '' },
//     { text: 'Legal separations & divorce can be tumultuous & most painful times in your life. It can be too much to take, especially when you have children. Apart from this, such legal family issues can impact many other aspects of your life, including Parental rights, Assets & Personal properties. These are very crucial aspects of your life & hence you need the proper guidance from the best family lawyer in Ontario. You cannot rely on anyone other than a professional & experienced team of family law specialists like Ardent Law firm. ' },
//     { text: 'Ardent Law firm, can help you in multiple ways. From court cases & arbitration to mediation, we can help you get the best outcome from your legal family issues' },
//     { text: 'We understand separations can be heart-breaking & tumultuous. So at Ardent Law firm, we offer reliable guidance & suitable options to you. Apart from the proper guidance, we offer an emotionally helping environment to help you make the right decision.' },
//     { text: 'Our team of lawyers takes a unique approach to help you settle your legal family disputes in or out of court. Our team is always ready to handle your case in court, no matter the complexities. We will handle every part of your case & will represent you in the best possible manner. Instead of going with the same strategy for all clients, we craft our approach according to each client’s unique case. This helps us get a better outcome for our valued clients in family matters. ' },
//     { text: 'From the negotiations for Property divisions, personal assets & parental rights, we constantly stay in touch with you to offer the right advice. Apart from our professional legal services to handle your case in court, we also offer unbundled legal services designed specifically for you' },
    
// ]

const services = [
 
    {
        icon: config.PageMenu.CivilLitigation.icon,
        title: config.PageMenu.CivilLitigation.title,
        content: config.PageMenu.CivilLitigation.content,
        link:config.PageMenu.CivilLitigation.link
    },
    {
        icon: config.PageMenu.BusinessLaw.icon,
        title: config.PageMenu.BusinessLaw.title,
        content: config.PageMenu.BusinessLaw.content,
        link:config.PageMenu.BusinessLaw.link
    },
    {
        icon: config.PageMenu.CriminalLaw.icon,
        title: config.PageMenu.CriminalLaw.title,
        content: config.PageMenu.CriminalLaw.content,
        link:config.PageMenu.CriminalLaw.link
    },
    {
        icon: config.PageMenu.Immigration.icon,
        title: config.PageMenu.Immigration.title,
        content: config.PageMenu.Immigration.content,
        link:config.PageMenu.Immigration.link
    },
    {
        icon: config.PageMenu.RealEstateLaw.icon,
        title: config.PageMenu.RealEstateLaw.title,
        content: config.PageMenu.RealEstateLaw.content,
        link:config.PageMenu.RealEstateLaw.link
    },
    {
        icon: config.PageMenu.WillsEstate.icon,
        title: config.PageMenu.WillsEstate.title,
        content: config.PageMenu.WillsEstate.content,
        link:config.PageMenu.WillsEstate.link
    },
  
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Family Law'}
]

const FamilyLaw = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Family Law"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title=""
                subTitle="Family Law"
                images={familyLaw}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            {/* <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Qualified Attorneys "
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default FamilyLaw