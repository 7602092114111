import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import { createAPIEndpoint, ENDPOINTS } from  "../../api";
// images
import testmonial from "../../images/testimonial/1.png";
import testmonial2 from "../../images/testimonial/2.png";
 
// const sliders = [
//   {
//     text: "Very professional people. They took time with my application, never rush me through my appointment. I would highly recommend them for your application process.",
//     images: testmonial2,
//     title: "Yogesh Goyal",
//     subTitle: "",
//   },
//   {
//     text: "This Ardent Law corporations was a saviour for my work visa after two refusals due to previous immigration consultants mistake this firm especially Gurkanwarpal and his partner took the right steps towards my file to get my work permit approval! Couldn’t ask for more am very thankful that I reached out to this firm. I can definitely recommend this Ardent Law firm for your any immigration situation.",
//     images: testmonial2,
//     title: "Kamal Dhillon",
//     subTitle: "",
//   },
//   {
//     text: "I am very happy with the services provided by Ardent Law Professional Corporation. Today, I got a great news for my parent’s visitor visa approval. Gurkanwar Sir handled my application and the whole experience with Sir and his team was good. They are very patient and hard working. I got every update about my file on time. Thank you so much Ardent Law Professional Corporation.",
//     images: testmonial2,
//     title: "Sukhman Rattol",
//     subTitle: "",
//   },
//   {
//     text: "I recently purchased a house and had Ardent law represent me for my purchase. Being a first time homebuyer, I was really nervous but the entire team was really helpful throughout out the process which kept me at ease. I highly recommend Ardent law for all your real estate needs.",
//     images: testmonial2,
//     title: "Waryam Dhillon",
//     subTitle: "",
//   },
//   {
//     text: "Highly professional team that helped me to get my PR. They are always available 24/7 to clear client concerns and inquiries. Especially I would like to thank aafren sharma for her guidance and help throughout.",
//     images: testmonial2,
//     title: "Sahajdeep Brar",
//     subTitle: "",
//   },
//   {
//     text: "Highly professional team. Impeccable service. They really went out of the way and helped me in my case. Would definitely recommend this firm.",
//     images: testmonial2,
//     title: "Jasreman Singh",
//     subTitle: "",
//   },
// ];

function getimage(src){
if(src==="")
{
  return testmonial2;
}
else
{
return "data:image/png;base64,"+src;
}
}


const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};
const Testmonial = ({ className }) => {
  //Start
const [data, setData] = useState([]);

// const fetchData = () => {
//   fetch(`http://api.ardentlawfirm.com/api/gettestimonials`)
//     .then((response) => response.json())
//     .then((actualData) => {
//       console.log(actualData);
//       setData(actualData);
//      // console.log(data);
//     })
//     .catch((err) => {
//       console.log(err.message);
//     });
// };

useEffect(() => {
  createAPIEndpoint(ENDPOINTS.getTestimonials)
  .post()
  .then(res =>
     {
     
    setData(JSON.parse(res.data));
   // console.log(res.data);
  })
  .catch(err => {   
    //console.log(err.data);
});
}, []);
//End

  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="testimonialImages">
              <img src={testmonial} alt="" />
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1 col-md-6">
            <div className="testimonialContent">
              <span>What People Say</span>
              <h2>Client Testimonial</h2>
              <Slider className="testmonialSlider" {...settings}>
                {data.map((slider, i) => (
                  <div key={i} className="slideItem">
                    <p>{slider.stext}</p>
                    <div className="thumbWrap">
                      <div className="thumbImg">
                        <img src={
                              getimage(slider.bImage)
                          
                          } alt="" />
                      </div>
                      <div className="imgContent">
                        <h4>{slider.sTitle}</h4>
                        <span>{slider.sSubTitle}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testmonial;

// const Testmonial = ({ className }) => {
//   return (
//     <div className={className}>
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-4 col-md-6">
//             <div className="testimonialImages">
//               <img src={testmonial} alt="" />
//             </div>
//           </div>
//           <div className="col-lg-7 offset-lg-1 col-md-6">
//             <div className="testimonialContent">
//               <span>What People Say</span>
//               <h2>Client Testimonial</h2>
//               <Slider className="testmonialSlider" {...settings}>
//                 {sliders.map((slider, i) => (
//                   <div key={i} className="slideItem">
//                     <p>{slider.text}</p>
//                     <div className="thumbWrap">
//                       <div className="thumbImg">
//                         <img src={slider.images} alt="" />
//                       </div>
//                       <div className="imgContent">
//                         <h4>{slider.title}</h4>
//                         <span>{slider.subTitle}</span>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Testmonial;
